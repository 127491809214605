import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useLocation } from '@reach/router'
import { FlexContainer } from '../elements'
import Spinner from '../components/spinner'
import styled from 'styled-components'
import done from '../images/checkmark.svg'
import erroricon from '../images/error.svg'
import { getImage } from '../imageContainers'
import { Link } from 'gatsby'
import {
  ONSUCCESS,
  ONFAIL,
  ERRORS,
  IMPROVE,
  STEPS,
} from '../CMS/PaymentResponse'

const ErrorIcon = () => {
  return (
    <div style={{ width: '45px', padding: '0' }}>
      <img alt="error" className="error" src={erroricon} />
    </div>
  )
}

const PaymentResponse = ({ className }) => {
  const { href } = useLocation()
  const [status, setStatus] = useState({
    loadingExternal: true,
    loadingInternal: true,
    sendingEmail: true,
  })

  const lang = 'en'

  const [error, setError] = useState({ isError: false, errorMessage: '' })

  const verifyPaymentInServer = () => {
    // verify
    setTimeout(
      () =>
        setStatus({
          ...status,
          loadingExternal: false,
          loadingInternal: false,
        }),
      2000
    )
    setTimeout(
      () =>
        setStatus({
          loadingExternal: false,
          loadingInternal: false,
          sendingEmail: false,
        }),
      3000
    )
  }

  useEffect(() => {
    if (href.split('&').length < 23) {
      // display 404?
      setTimeout(
        () => setError({ isError: 'step1', errorMessage: ERRORS[lang][0] }),
        1000
      )
    } else {
      setTimeout(() => setStatus({ ...status, loadingExternal: false }), 1000)
      if (href.includes('success=true')) {
        verifyPaymentInServer()
      } else {
        setTimeout(
          () => setError({ isError: 'step1', errorMessage: ERRORS[lang][1] }),
          1000
        )
      }
    }
  }, [])

  return (
    <Layout lang={lang}>
      <SEO title="Payment Response" />
      <FlexContainer
        className={className}
        direction="column"
        alignItems="center"
      >
        <FlexContainer
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div className="spinnerWidth">
            {error.isError === 'step1' ? (
              <ErrorIcon />
            ) : status.loadingExternal ? (
              <Spinner />
            ) : (
              <img alt="done" className="done" src={done} />
            )}
          </div>
          <p className="text">{STEPS[lang][0]}</p>
        </FlexContainer>
        {error.isError !== 'step1' && (
          <FlexContainer
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={status.loadingExternal ? 'hidden' : ''}
          >
            <div className="spinnerWidth">
              {error.isError === 'step2' ? (
                <ErrorIcon />
              ) : status.loadingInternal ? (
                <Spinner />
              ) : (
                <img alt="done" className="done" src={done} />
              )}
            </div>
            <p className="text">{STEPS[lang][1]}</p>
          </FlexContainer>
        )}
        {error.isError !== 'step1' && error.isError !== 'step2' && (
          <FlexContainer className={status.loadingInternal ? 'hidden' : ''}>
            <FlexContainer
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <div className="spinnerWidth">
                {error.isError === 'step3' ? (
                  <ErrorIcon />
                ) : status.sendingEmail ? (
                  <Spinner />
                ) : (
                  <img alt="done" className="done" src={done} />
                )}
              </div>
              <p className="text">{STEPS[lang][2]}</p>
            </FlexContainer>
          </FlexContainer>
        )}
        {!error.isError ? (
          <FlexContainer
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={
              status.sendingEmail ? 'hidden' : 'messageContainer Success'
            }
          >
            <img alt="done" className="done" src={done} />
            <p className="message title">{ONSUCCESS[lang]['title']}</p>
            <p className="message">{ONSUCCESS[lang]['message']}</p>
            <p className="noMargin small">{ONSUCCESS[lang]['note'][0]}</p>
            <p className="noMargin small">
              {ONSUCCESS[lang]['note'][1]}
              <Link target="_blank" to="/contact-us">
                {ONSUCCESS[lang]['note'][2]}
              </Link>{' '}
              {ONSUCCESS[lang]['note'][3]}
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.shamseya.melior"
              target="blank"
              className="link"
            >
              {getImage('google-play-badge.png')}
            </a>

            <p className="improve">{IMPROVE[lang]}</p>
          </FlexContainer>
        ) : (
          <FlexContainer
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={'messageContainer Error'}
          >
            <ErrorIcon />
            <p className="message title"> {ONFAIL[lang]} </p>
            <p className="message">{error.errorMessage}</p>
          </FlexContainer>
        )}
      </FlexContainer>
    </Layout>
  )
}

export default styled(PaymentResponse)`
  min-height: 80vh;
  margin-top: 200px;
  @media (max-width: 768px) {
    margin-top: 150px;
  }
  @media (max-width: 425px) {
    margin-top: 100px;
  }
  img {
    animation: createBox 0.25s;
  }

  .link {
    z-index: 10;
    color: black;
    cursor: pointer;
    margin-top: 20px;
  }
  .hidden {
    opacity: 0px;
    display: none;
  }
  .spinnerWidth {
    width: 80px;
  }
  .done {
    width: 45px;
    color: green;
  }
  .text {
    margin-top: 20px;
    font-size: 1rem;
    width: 150px;
  }
  .message {
    margin: 10px;
    margin-bottom: 20px;
  }
  .noMargin {
    margin: 0px;
  }
  .small {
    font-size: 0.8rem;
  }
  .title {
    font-size: 1.3rem;
  }
  .improve {
    font-size: 1.5rem;
    margin: 10px;
    margin-left: auto;
  }
  .messageContainer {
    min-width: 80%;
    min-height: 15rem;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    p {
      text-align: center;
    }
  }
  .Success {
    background: #1db40144;
    border: 1px solid #1db40199;
    box-shadow: 5px 8px 18px #8888;
    animation: createBox 0.5s;
  }
  .Error {
    background: #ba361644;
    border: 1px solid #ba361699;
    box-shadow: 5px 8px 18px #8888;
    animation: createBox 0.5s;
  }
  @keyframes createBox {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
`
